@import "Colors";

.service-offerings {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h3 {
    margin-bottom: 20px;
  }
  
  .category-grid {
    // display: grid;
    // grid-template-columns: "a a" "b b" "c"; /* responsive grid */
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-template-areas:
      "hd hd"
      "sd sd"
      "ft ft";
  }
  
  .category-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey-border;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    background-color: $white;
    text-align: center;
    cursor: pointer;
    // height: 109px;
    // width: 170px;
    
  }
  
  .category-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .category-card p {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: #4caf50; /* Green checkmark */
  }
  

  .wrapper {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-template-areas:
      "hd hd hd hd   hd   hd   hd   hd   hd"
      "sd sd sd main main main main main main"
      "ft ft ft ft   ft   ft   ft   ft   ft";
  }

  .header {
    grid-area: hd;
  }
  .footer {
    grid-area: ft;
  }
  .content {
    grid-area: main;
  }
  .sidebar {
    grid-area: sd;
  }

  .availability-checkbox{
    .ant-checkbox+span{
      background-color: $light-grey;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      color: $neutrals;
      font-family: 'sf-regular';
    }
  
    .ant-checkbox-checked+span{
      background-color: $theme-color;
      color: $white;
    }
  
  
  .ant-checkbox{
      display: none;
  }
  }


.ant-upload-wrapper .ant-upload-drag{
  display: flex;
  align-items: center !important;
  border-radius: 16px;
  flex-direction: row !important;
}

.mbs-line{
  font-size: 10px;
  font-family: 'sf-regular';
  color: $neutrals;
}

.upload-btn{
  background-color: $tertiary-yellow !important;
  border-color: $tertiary-yellow !important;;
  border-radius: 70px !important;
  height: 35px !important;
}

.preview-image-container{
  border: 1px solid $neutrals;
  border-radius: 16px;
  margin-top: 10px;
}

.preview-image-view-container{
  background-color: $white;
  border-radius: 16px;
}

.vendor-detail-description{
    padding: 12px 16px;
    font-size: 14px;
    font-family: 'sf-regular';
}