.name-prev{
  width: 80%;
  text-align: left;
  margin-left: 10px;
}
.imageMSG{
display: flex;
justify-content: end;
}
.img-msg-time {
right: 10px;
font-size: 12px;
color: #999;
text-align: end;
position: absolute;
}
.chat-container {
  display: flex;
  background-color: #f8f8f8;
  height: 82vh;

  .chat-sidebar {
    width: 35%;
    background-color: #ffffff;
    padding: 20px;
    border-right: 1px solid #e0e0e0;
    .admin-header{
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #cdcdcd #f1f1f1;
      height: 90%;
    }
  }

  .chat-messages {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: calc(100% - 35%);

    h3 {
      margin-top: 10px;
      margin-left: 15px;
      align-content: center;
      color: #333333;
      font-weight: bold;
    }

    .messages-list {
      flex-grow: 1;
      overflow-y: auto;
      margin-bottom: 10px;
      scrollbar-width: thin;
      scrollbar-color: #cdcdcd #f1f1f1;
      padding: 1rem;

      .message-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1.5rem;
      }

      .date-divider {
        text-align: center;
        margin: 1rem 0;
        position: relative;
        
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          height: 1px;
          background: #e0e0e0;
          z-index: 1;
        }
        
        span {
          position: relative;
          background: #f8f8f8;
          padding: 0.5rem 1rem;
          border-radius: 1rem;
          font-size: 0.875rem;
          color: #666;
          display: inline-block;
          z-index: 2;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
      }

      .message {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding-left: 10px;
        padding-right: 10px;

        &.sent {
          flex-direction: row-reverse;
          .message-content {
            background-color: #e0d9f6;
            color: #333;
            border-radius: 10px 10px 0px 10px;
            position: relative;
            padding-bottom: 20px;
          }

          .sender {
            color: #7e57c2;
          }
        }

        &.received {
          flex-direction: row;
          .message-content {
            background-color: #f9f7d9;
            color: #333;
            border-radius: 10px 10px 10px 0px;
            position: relative;
            padding-bottom: 20px;
          }

          .sender {
            color: #c7a002;
          }
        }

        .message-content {
          max-width: 60%;
          padding: 10px 15px;
          font-size: 14px;
          line-height: 1.5;
          position: relative;
          min-width: 120px;

          .sender {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
          }

          .message-time {
            position: absolute;
            right: 10px;
            font-size: 12px;
            color: #999;
          }
        }
      }
    }

    .message-input {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 10px;
      padding-bottom: 10px;
      .attach-btn {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3A2252;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
        img{
          margin-top: -3px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(641%) hue-rotate(175deg) brightness(106%) contrast(106%);
        }
      }

      input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 20px;
        outline: none;
        font-size: 14px;
      }

      .send-btn {
        background: #3A2252;
        color: white;
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.chat-user {
display: flex;
align-items: center;
border-radius: 7px;
text-align: center;

.user-icon {
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.user-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.user-preview {
  font-size: 14px;
  color: #666666;
  margin: 0;
}
}

.received-message {
background-color: #c7a002;
}
.sent-message {
background-color: #7e57c2;
}