.custom-card {
  border: none !important;
  padding: 20px;
  color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px;
  background-color: #fff;
  border-radius: 20px !important;
  margin-bottom: 10px;

  .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
  }

  .currency {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
  }

  .card-bottom {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;

    .icon-container {
      width: 48px;
      height: 48px;
      margin-right: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #717171;
    }
  }
}

// Responsive Design
@media screen and (max-width: 599px) {
  .custom-card {
    padding: 15px;
    min-height: 150px;
  }

  .card-top {
    font-size: 20px;
  }

  .icon-container {
    width: 40px;
    height: 40px;
  }

  .heading {
    font-size: 16px;
  }
}
