@import "Colors";


.addUserBtn{
  width: min-content;
  background-color: $add-btn !important;
  border-color: $add-btn;
  color: $black !important;
  font-family: 'sf-semibold' !important;
}

.searchInput{
  margin-right: 20px;
  width: max-content;
  border: 1px solid white !important;
}

.status-listing{
  border-radius: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'sf-regular';
  padding: 5px 0px;
}

.bookingAdmin{
  color: $warning-800;
  border: 1px solid $warning-800;
}

.financeManager{
  color: $tertiary;
  border: 1px solid $tertiary;
}

.eventManager{
  color: $success;
  border: 1px solid $success;
}

.PhoneInputInput {
  border: none;
  outline: none;
  font-size: 16px;
}

.cancelBtn{
  margin-right: 8px;
  width: 100%;
  background-color: $white !important;
  color: $theme-color !important;
  border-color: $theme-color !important;
}

.rejectBtn{
  margin-right: 8px;
  width: 100%;
  background-color: transparent !important;
  color: $error !important;
  border-color: $error !important;
}

.phone-no-input{
  width: 100%;
}

.bold-name-circle{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  font-size: 20px;
  font-family: 'sf-bold';
}

.detail-label{
font-family: 'sf-regular';
font-size: 12px;
color: $black;
}


.detail-value{
  font-family: 'sf-semibold';
  font-size: 16px;
  color: $black;
  
}

.calendar-div{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer !important;
}

.calendar-wrapper {
  position: relative; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-range-container {
  margin-top: 10px;
  display: block;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute; 
  top: 40px; 
  z-index: 1000;
  padding: 10px;
  transition: all 0.3s ease; 
}

.status-tag{
  border-radius: 20px;
  padding: 5px 15px;
  // font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: $white !important;
  // min-width: 50px;
  // max-width: 220px;
  // max-width: fit-content;
  // width: fit-content;
}

.freelancer-heading{
font-family: 'sf-semibold';
font-size: 18px;
color: black;
}

.initial-approval{
  font-family: 'sf-semibold';
font-size: 12px;
color: $tertiary-yellow;
}

.approval-tab{
  border: 1px solid #CECECE;
    border-radius: 8px;
    cursor: pointer;
    padding: .50rem;
}

.details-and-meet-link{
display: grid;
grid-template-columns: 3fr 2fr;
}

.icon-white{
  filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(13%) hue-rotate(81deg) brightness(106%) contrast(106%) !important;
}
.view-container {
  background-color: #f1f1f1;
  padding: 20px;
  /* Ensure vertical alignment */
  border-radius: 20px;
}

.view-container-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-container > div {
  flex: 1; /* Allow the div to take available space */
}

.btn-unfilled {
  border: 1px solid $theme-color !important;
  color: $theme-color !important;
  border-radius: 20px !important;
  padding: 5px 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: $white !important;
  // height: 40px;
}

.detail {
  font-weight: 600;
  font-size: 18px;
}

.fs-16{
font-size: 16px !important;
}

.upload-container {
  width: 300px;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  text-align: center;
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  // flex-direction: column;
}
.font-bold{
  font-weight: bold;
}

.upload-button {
  background-color: #FFC107;
  border: none;
  border-radius: 20px;
  padding: 0px 20px;
  color: white;
  font-weight: 400;
  cursor: pointer;
}

.upload-info {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}


// .image-preview-container {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 5px 10px;
//   margin-top: 10px;
//   border-radius: 8px;
//   background: #F0F0F0; /* Light grey background */
//   color: black;
//   position: relative;
// }

.image-preview-name {
  display: flex;
  align-items: center;
  gap: 10px;
  width: '70px';
   height: '70px';
    overflow: 'hidden'
}

.image-preview-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: '100%';
   height: '100%';
   object-fit: 'cover'
}

.btn-reject{
  color: #F55666;
  border: 1px solid #F55666;
  border-radius: 10px;
  padding: 5px 20px;
  cursor: pointer;
}

.notification-count {
  position: relative;
  // display: inline-block;
}

.notification-badge {
  position: absolute;
  bottom: 0; 
  right: 10px; 
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 4px; 
  font-size: 12px; 
  height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}


/* Print-specific styles */
@media print {
  .view-container .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }

  .view-container .col-lg-4,
  .view-container .col-md-6,
  .view-container .col-sm-12 {
    flex: 0 0 33.33%; /* Three columns per row */
    max-width: 33.33%;
    padding: 5px; /* Add padding for spacing */
  }

  .bold-name-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
  }

  .user-name {
    font-size: 14px;
    font-weight: bold;
  }

  .detail-label {
    color: #555;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .detail-value {
    font-size: 14px;
    color: #000;
  }
@page {
    margin: 20px; 
  }
  .item {
    page-break-inside: avoid; 
  }

  .page-break {
    page-break-after: always; 
  }
}

.permissions-container {
  padding: 20px;
  background-color: #f0f2f5;
  // min-height: 100vh;
}

.role-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.permissions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submenu-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.ant-card {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.parent-menu {
  background-color: #fafafa;
  font-weight: bold;
}

.parent-row-highlight {
  background-color: #fafafa;
  cursor: pointer;
}

.custom-checkbox {
  transform: scale(1.3);
}

.ant-table-cell {
  text-align: left;
}

.expand-icon {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
