@import url(./fonts/fonts.scss);
@import url(./Auth.scss);
@import url(./Users.scss);
@import url(./VendorFreelancer.scss);
@import url(./card.scss);
@import "Colors";
@import "toastr";

.inputfield{
    height: 45px;
    border-radius: 8px;
    border: 1px solid $grey;
    font-size: 14px;
    font-family: 'sf-semibold'!important;
    // width: 100%;
}

.inputfield-label{
 font-size: 14px;
 color: $black;
 font-family: 'sf-semibold';
}

.terms-html-content{
  height: 150px !important;
  overflow :auto;
  padding: 20px;
  border: 1px solid #c7c6c6;
  border-radius: 10px;
}

.ant-input-affix-wrapper>input.ant-input{
 font-size: 14px;
    font-family: 'sf-semibold'!important;
}

.ant-input::placeholder {
    font-family: 'sf-regular';
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #FFFFFF;
    background: $secondary;
    border-color: $secondary;
}

.buttonStyle{
    background-color: $theme-color;
    height: 45px;
    // width: 100%;
    color: $white;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'sf-semibold';
    display: flex;
    justify-content: center;
    align-items: center;
}
.h-35{
    height: 35px !important;
  }

.ant-upload-list-item-error{
  display: none !important;
}

.ant-upload-list {
  display: none !important;
}

.user-name{
    font-size: 16px;
    color: $black;
    font-family: 'sf-semibold';
}

.page-heading{
    font-size: 24px;
    color: $black;
    font-family: 'sf-bold';
   }

   .list-heading{
    font-size: 20px;
    color: $black;
    font-family: 'sf-semibold';
   }

   .loader-modal{
    width: 147px !important;

    .ant-modal-close{
      display: none;
    }
   }

  .ant-switch.ant-switch-checked {
    background: $success;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: $success-tint;
}
   /* CustomTabs.css */
.custom-tab {
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'sf-semibold';
    cursor: pointer;
    transition: all 0.3s ease;
    height: 40px;
  }
  
  .custom-tab.active {
    background-color: $theme-color; /* Active tab background color */
    color: white; /* Active tab text color */
  }

  .custom-tab.active:hover {
    background-color: $theme-color-tint /* Active tab hover background color */
    
  }
  
  .custom-tab:not(.active) {
    color: #595959; /* Inactive tab text color */
  }
  
  .custom-tab:hover {
    background-color: #E1D7ED; /* Hover effect on inactive tabs */
  }

  .ant-tabs-ink-bar {
    display: none !important; /* Hide the default blue underline */
  }

  // ant-pagination

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
    font-size: 12px;
    font-family: 'sf-regular';
    color: #000000;
}

   /* ant table */

  .ant-table-wrapper .ant-table-thead >tr>th{
    background-color: $theme-color-tint;
    font-family: 'sf-semibold';
  }

  .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    position: unset;
  }

  .ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: none;
  font-size: 12px;
  font-family: 'sf-regular';
  color: #000000;
  }

  .ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 10px 16px;
  }


.ant-menu .ant-menu-item-selected {
    background-color: $theme-color;
    color: $white !important;
    .ant-menu-item-icon{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(96deg) brightness(105%) contrast(108%);
}
}


.ant-menu.ant-menu :not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $theme-color !important;
    color: white;
  }
  

  
 

  .ant-menu-inline .ant-menu-sub.ant-menu-inline .ant-menu-item{
    padding-left: 10px !important;
}

  .ant-menu .ant-menu-item{
    height: 45px;
    font-family: 'sf-regular';
    
  }

  .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    height: 50px;
    font-family: 'sf-regular';
    padding-left: 20px !important;

  }

  .ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title{
    color: $theme-color;
    border: 1px solid $theme-color;
  }

  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline{
    background-color: $white !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
    background-color: $theme-color-tint;
    color: $black;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
    background-color: $theme-color-tint;
    color: $black;
  }
  
  .ant-menu .ant-menu-item:hover {
    background-color: $theme-color-tint;
    color: 'lightgreen';
  }
  
//   .ant-menu  .ant-menu-submenu-active  {
//     color: white;
//     background: $theme-color-tint
// }

.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    padding-left: 40px;
    padding-right: 20px;
}

.ant-menu-inline .ant-menu-sub.ant-menu-inline .ant-menu-item .ant-menu-item-selected{
    padding: 0 !important;
}




// .ant-menu-sub.ant-menu-inline::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 5px;
//     height: 100%; /* Ensure the bar spans the full height of the submenu */
//     background-color: #EBEBEB; /* Default color of the vertical bar */
//     border-radius: 10px;
//     margin-left: 20px;
//     z-index: 1;
// }

// /* Define the highlight on the bar based on the selected item */
// .ant-menu-sub.ant-menu-inline .highlighted-bar {
//     position: absolute;
//     left: 0;
//     width: 5px;
//     background-color: #9c45db; /* Highlight color */
//     border-radius: 10px;
//     margin-left: 20px;
//     z-index: 2;
//     transition: top 0.3s ease; /* Smooth transition for highlighting */
// }








// /* Ensure the vertical bar spans only the sub-menu height */
// .ant-menu-sub.ant-menu-inline {
//     position: relative;
//     padding-left: 25px; /* Adjust padding to make room for the vertical bar */
// }

// .ant-menu-sub.ant-menu-inline::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 5px;
//     height: 100%; /* Spans the full height of the sub-menu */
//     background-color: #EBEBEB; /* Default color of the vertical bar */
//     border-radius: 10px;
//     margin-left: 10px;
//     z-index: 1;
// }

// /* Define the highlight on the bar based on the selected item */
// .ant-menu-sub.ant-menu-inline .highlighted-bar {
//     position: absolute;
//     left: 0;
//     width: 5px;
//     background-color: #9c45db; /* Highlight color */
//     border-radius: 10px;
//     margin-left: 10px;
//     z-index: 2;
//     transition: top 0.3s ease; /* Smooth transition for highlighting */
// }


/* Ensure the vertical bar spans only the sub-menu height */
.ant-menu-sub.ant-menu-inline {
    position: relative;
    // padding-left: 25px; /* Adjust padding to make room for the vertical bar */
}

.ant-menu-sub.ant-menu-inline::before {
    // content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%; /* Spans the full height of the sub-menu */
    background-color: #EBEBEB; /* Default color of the vertical bar */
    border-radius: 10px;
    margin-left: 30px;
    z-index: 1;
}

/* Define the highlight on the bar based on the selected item */
// .ant-menu-sub.ant-menu-inline .highlighted-bar {
//     position: absolute;
//     width: 5px;
//     background-color: #9c45db; /* Highlight color */
//     border-radius: 10px;
//     margin-left: 30px;
//     z-index: 2;
//     transition: top 0.3s ease; /* Smooth transition for highlighting */
// }

.highlighted-bar {
    position: absolute;
    width: 5px;
    background-color: $theme-color; /* Highlight color */
    border-radius: 10px;
    z-index: 2;
    margin-left: 30px;
    transition: top 0.3s ease; /* Smooth transition for highlighting */
    left: 0;
    margin-top: 50px;
  }
//radio
:where(.css-dev-only-do-not-override-1gwfwyx).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: $success !important;
    border-color: $success !important;
}

  // checkbox
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $success !important;
    border-color: $success !important;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: $success;
}


.ant-checkbox-checked:hover{
  background-color: $success !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3A2252 !important;
  border-color: #3A2252 !important;
}
.custom-checkbox .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #3A2252;
  }


.custom-checkbox .ant-checkbox-checked:hover{
  background-color: #3A2252 !important;
  }


.ant-picker-now-btn{
  color: $theme-color !important;
}
.ant-picker-ok .ant-btn{
  background-color: $theme-color !important;
  color: $white !important;
  font-family: 'sf-regular' !important;
}


.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: black;
}

.ant-modal-close{
  background-color: black !important;
  color: white !important;
  border-radius: 50% !important;
  height: 22px !important;
  width: 22px !important;
}

.anticon .anticon-close .ant-modal-close-icon{
  height: 7px !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light.ant-menu-root.ant-menu-vertical, :where(.css-dev-only-do-not-override-1gwfwyx).ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}

.ant-layout-sider{
  border-inline-end: 1px solid #E1E1E1;

}

.logout{
  color:#F55666;
  font-size: 12px;
  font-family: 'sf-regular';
}

.ant-pagination .ant-pagination-item{
  background-color: transparent;
  font-size: 12px;
  font-family: 'sf-regular';
}

.ant-pagination .ant-pagination-item-active{
  border-color: $white;
  background-color: $white;
  font-family: 'sf-semibold';
}

.ant-select-selector{
  border-style: none !important;
  box-shadow: none !important;
}

.ant-select-selector:hover{
  border-color: #000000 !important;
}


.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #A4A4A4;
}

.ant-picker-outlined:hover {
  border-color: #A4A4A4;
}

.ant-picker-outlined:focus {
  border-color: #A4A4A4;
  box-shadow: none !important;

}

.cursor-pointer{
  cursor: pointer;
}

.ant-select .ant-select-arrow {

  color: #000000;
}

.ant-modal-close{
  display: none !important;
}

// custom drop down for Order Tracking for disable or enable next 
.custom-select {
  width: 100%;
  border: 2px solid #b5b079;
  border-radius: 25px;
  padding: 10px 10px 6px 10px;
  font-weight: bold;
  color: #b5b079;
}

.custom-dropdown {
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.custom-dropdown .ant-select-item {
  padding: 10px 20px;
}

.custom-dropdown .ant-select-item:hover {
  background-color: #f0f0f0;
}

.custom-dropdown .ant-select-item-option-active {
  background-color: #e8e8e8 !important;
}

.no-padding.custom-select{
  height: 30px !important;
}
.terms-html-content{
  height: 150px !important;
  overflow :auto;
  padding: 20px;
  border: 1px solid #c7c6c6;
  border-radius: 10px;
}

.arabic-field .ql-editor {
  direction: rtl !important;
  text-align: right !important;
}

.dir-rtl{
  direction: rtl;
  text-align: right;
}

.w-300{
  width: 300px !important;
}

.rating-background{
  background-color: white;
  border-radius: 30px;
  max-width:160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}