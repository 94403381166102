@import "Colors";
.main-div {
  display: grid;
  grid-template-columns: 6fr 6fr;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  width: 100vw;
}

.strech-man-div{
  box-sizing: border-box;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  // min-width: 100vw;
  max-height: 100vh;
  background-image: url(../Images/bg-auth.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.event-planner{
    font-family: "sf-bold";
  font-size: 32px;
  color: $theme-color;
  margin-left: 30px;
  margin-top: 20px;
}

.login-heading {
  font-family: "sf-semibold";
  font-size: 28px;
  color: $black;
}


.login-des {
  font-family: "sf-regular";
  font-size: 16px;
  color: $black;
}

.forgot-pas-desc {
  font-family: "sf-regular";
  font-size: 13px;
  color: $theme-color;
  text-align: right;
  cursor: pointer;
}

.ant-form-item{
  margin-bottom: 12px;
}

.ant-input-outlined:focus, .ant-input-outlined:focus-within {
  border-color: $black;
  box-shadow: 0 0 0 0px $black; 
  outline: 0;
}

.ant-input-outlined:hover {
  border-color: $black;
}

.success-modal .ant-modal-content{
  width: 65%;
}

.auth-btn{
  width: 100%;
}

.auth-logo{
  height: 45px;
  // width: 150px;
}

.modal-logo{
  height: 40px;
}

.admin{
font-family: 'sf-regular';
font-size: 12px;
color: $neutrals;

}