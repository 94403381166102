
@font-face {
  font-family: sf-regular;
  src: url(../../../assets/fonts/29ltbukraregular.otf) format("opentype");
}
@font-face {
  font-family: sf-boldItalic;
  src: url(../../../assets/fonts/29ltbukrabolditalic.otf) format("opentype");
}
//600
@font-face {
  font-family: sf-semibold;
  src: url(../../../assets/fonts/29LTBukra-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: sf-bold;
  src: url(../../../assets/fonts/29ltbukrabold.otf) format("opentype");
}

@font-face {
  font-family: sf-light;
  src: url(../../../assets/fonts/29ltbukralight.otf) format("opentype");
}

.sf-regular {
  font-family: sf-regular !important;
}

.sf-bold {
  font-family: sf-bold !important;
}

.sf-semibold {
  font-family: sf-semibold !important;
}

.sf-light {
  font-family: sf-light !important;
}

.sf-medium {
  font-family: sf-medium !important;
}