@import url(_Colors.scss);
$theme-color:#3A2252;
$primary: #9D74C5;
$theme-color-tint:#E1D7ED;
$secondary:#5b476f;
$black: #0A0A0B;
$grey: #A4A4A4;
$white: #FFFFFF;
$add-btn: #D0C64F;
$warning-800: #C6A500;
$tertiary:#D02B6D;
$success: #07C559;
$success-tint:#88c3a2;
$neutrals: #A6A4A4;
$error: #F55666;
$tertiary-yellow:#F29E0F;
$grey-border: #BBB9B9;
$light-grey: #E5E5E5;